@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&family=IBM+Plex+Sans:wght@400;500&display=swap');
@import 'style.css';

.react-icons {
  vertical-align: middle;
}

.logo {
  height: 32px;
  margin-right: 8px;
  margin-top: -2px;
}

.loader {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto !important;
  height: 42px;
}

.break-all, .break-all a {
  word-break: break-all;
}

.ant-message {
  word-break: break-all;
}

.ant-menu .ant-menu-item .react-icons {
  margin-right: 8px;
  margin-top: -2px;
}

.ant-menu .ant-menu-item .react-icons.react-icons-chains {
  margin-right: 7px;
}

.ant-menu .ant-menu-item .nav-text {
  text-transform: uppercase;
}

.ant-table {
  font-family: 'IBM Plex Sans', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
  border: 1px solid #f0f0f0;
}

.ant-table th.filter-extid .ant-table-filter-trigger-container {
  right: auto;
  left: 110px;
}

.ant-table th.filter-entryhash .ant-table-filter-trigger-container {
  right: auto;
  left: 100px;
}

.ant-table th.filter-paying-address .ant-table-filter-trigger-container {
  right: auto;
  left: 129px;
}

.ant-table .ant-table-expanded-row-fixed {
  width: auto !important;
}

.ant-table .ant-table-thead th {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

.ant-table .ant-table-row .code {
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace;
}

.ant-table .ant-table-row .react-icons, .ant-descriptions .react-icons, .ant-list .react-icons, .heights .react-icons {
  margin-right: 5px;
  opacity: 0.5;
  margin-top: -2px;
}

.ant-table .ant-table-footer {
  background: #FFF;
  padding: 12px;
}

.ant-table .ant-table-row .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 28px);
  padding-right: calc(2em + 28px);
}

.ant-list .react-icons, .featured .react-icons {
  margin-top: -2px;
}

.ant-list ul {
  margin-bottom: 5px !important;
}

.ant-list div.ant-typography:last-child {
  margin-bottom: 0;
}

.ant-descriptions {
  margin-bottom: 25px;
  font-family: 'IBM Plex Sans', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
}

.ant-descriptions-view {
  overflow: auto;
}

.ant-descriptions-item-label {
  text-align: left;
}

.ant-descriptions-item-content {
  background: #FFF;
}

.ant-descriptions-item-content .code {
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace;
}

.description-address .ant-descriptions-view table {
  width: auto !important;
}


.skeleton-holder {
  background: #FFF;
  padding: 12px;
  border-color: #f0f0f0;
  margin-bottom: 25px;
}

h2.ant-typography, h4.ant-typography:not(.ant-typography-secondary) {
  color: #000 !important;
}

h4.ant-typography {
  margin-bottom: 15px !important;
}

h4.ant-typography-secondary {
  margin-bottom: 20px !important;
}

h4.ant-typography .react-icons {
  margin-right: 6px;
  color: #5f26dd;
  margin-top: -3px;
}

h4.ant-typography .react-icons-tooltip {
  margin-left: 6px;
  color: rgba(0,0,0,0.4);
  vertical-align: middle;
  font-size: 75%;
}

h4.ant-typography .react-icons.react-icons-eblocks {
  margin-right: 8px;
}

h4.ant-typography .count {
  background: rgba(255,255,255,0.85);
  color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 12px;
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace;
  font-weight: normal;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
}

.ant-list .ant-list-item {
  background: #FFF;
  padding: 16px !important;
}

.ant-list .ant-list-item pre, .entry-content .ant-tabs-top-content > .ant-tabs-tabpane pre, .entry-content .ant-tabs-top-content > .ant-tabs-tabpane .content, .anchor-info .ant-tabs-top-content > .ant-tabs-tabpane pre, .anchor-info .ant-tabs-top-content > .ant-tabs-tabpane .content {
  margin: 0;
  width: 100%;
  box-shadow: 0 0 0 1px #f0f0f0 !important;
  background: #fcfcfc !important;
  padding: 7px;
}

.ant-list .ant-list-item code, .entry-content .ant-tabs-top-content > .ant-tabs-tabpane code, .anchor-info .ant-tabs-top-content > .ant-tabs-tabpane code {
  margin: 0;
  width: 100%;
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace !important;
}

.network-badge {
  display: none !important;
  position: absolute !important;
  top: 16px;
  right: 22px;
}

@media only screen and (min-width: 768px) {
  .network-badge {
    display: block !important;
  }
}

.network-badge {
  background: rgba(255,255,255,0.08) !important;
  border-color: rgba(255,255,255,0.2) !important;
  padding-left: 12px !important;
  padding-right: 10px !important;
}

.network-badge .anticon-down {
  color: rgba(255,255,255,0.5) !important;
}

.network-badge .ant-badge-status-text {
  color: #FFF;
}

.network-badge .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.network-badge .ant-badge-status-success::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #52c41a;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}

.ant-layout {
  background: #f3f5f8 !important;
}

.ant-layout-footer {
  background: #ffffff !important;
}

.explorer-tooltip .ant-tooltip-inner {
  background: rgba(0,0,0,0.9) !important;
  padding: 5px 8px !important;
  font-size: 13px;
  margin: 0 20px;
}

.extid {
  width: auto !important;
  display: block !important;
}

.ant-table .extid {
  float: left !important;
  margin-right: 15px !important;
}

.extids {
  margin-bottom: 30px;
}

.extids .extid {
  margin-bottom: 10px;
}

.extid .ant-typography {
  line-height: 18px;
  padding: 7px 11px 6px;
}

.extids .extid-ASCII .ant-typography, .extids .extid-empty {
  padding-top: 6px !important;
  padding-bottom: 7px !important;
}

.extid .ant-select {
  width: 89px !important;
}

.extid .ant-select .ant-select-selector {
  height: 33px !important;
}

.extid .extid-text, .extid .extid-type {
  font-size: 13px !important;
}

.extids .extid-text {
  max-width: -moz-calc(100% - 89px);
  max-width: -webkit-calc(100% - 89px);
  max-width: calc(100% - 89px);
}

.extid.extid-Base64 .extid-text:not(.extid-empty), .extid.extid-Hex .extid-text:not(.extid-empty) {
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace !important;
}

.extid .extid-text {
  background: #FFF;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
  word-break: break-all;
  overflow: hidden;
}

.ant-table .extid .extid-text {
  border-left-width: 0 !important;
}

.extid .extid-type.ant-typography, .extid .extid-type .ant-select-selector, .type-radio .ant-radio-button-wrapper-checked.type-ASCII, .type-radio .ant-radio-button-wrapper-checked.type-ASCII:hover, .type-radio .ant-radio-button-wrapper-checked.type-ASCII:not(.ant-radio-button-wrapper-disabled)::before {
  background: #001529 !important;
  border: 1px solid #001529 !important;
  color: #FFF !important;
}

.extid-Base64 .extid-type.ant-typography, .extid-Base64 .extid-type .ant-select-selector, .extid-Hex .extid-type.ant-typography, .extid-Hex .extid-type .ant-select-selector, .type-radio .ant-radio-button-wrapper-checked.type-Base64, .type-radio .ant-radio-button-wrapper-checked.type-Hex, .type-radio .ant-radio-button-wrapper-checked.type-JSON, .type-radio .ant-radio-button-wrapper-checked.type-Base64:hover, .type-radio .ant-radio-button-wrapper-checked.type-Hex:hover, .type-radio .ant-radio-button-wrapper-checked.type-JSON:hover, .type-radio .ant-radio-button-wrapper-checked.type-Base64:not(.ant-radio-button-wrapper-disabled)::before, .type-radio .ant-radio-button-wrapper-checked.type-Hex:not(.ant-radio-button-wrapper-disabled)::before, .type-radio .ant-radio-button-wrapper-checked.type-JSON:not(.ant-radio-button-wrapper-disabled)::before {
  background: #1a7eea !important;
  border-color:  #1a7eea !important;
}

.extid.extid-compact .extid-type {
  cursor: pointer;
}

.type-radio .ant-radio-button-wrapper:hover {
  color: rgba(0, 0, 0, 0.65) !important;
}

.type-radio .ant-radio-button-wrapper-checked:hover {
  color: #FFFFFF !important;
}

.extid .ant-select-arrow {
  color: rgba(255,255,255,0.5) !important;
}

.extid-tag {
  margin-top: 5px !important;
  margin-right: 0 !important;
}

.extid .extid-text .ant-typography-copy {
  opacity: 0.5;
}

.entry-content .ant-tabs-bar, .anchor-info .ant-tabs-bar {
  display: none !important;
}

.entry-content {
  padding: 16px !important;
  background: #FFF !important;
}

.anchor-info .ant-tabs {
  padding: 1px !important;
  background: #FFF !important;
}

.entry-content .ant-tabs-top-content.ant-tabs-content-animated, .entry-content .ant-tabs-bottom-content.ant-tabs-content-animated, .anchor-info .ant-tabs-top-content.ant-tabs-content-animated, .anchor-info .ant-tabs-bottom-content.ant-tabs-content-animated {
  transition: none !important;
}

.entry-content .code, .anchor-info .code {
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace;
}

.stats {
  margin-bottom: 4px;
}

.stats .react-icons {
  margin-right: 0;
  color: #5f26dd;
  opacity: 0.5;
  font-size: 24px;
  margin-bottom: 5px;
}

.stats .react-icons.react-icons-entries {
  margin-left: -2px;
}

.stats h3.ant-typography {
  font-weight: normal !important;
  margin-bottom: 0 !important;
  margin-top: 4px;
}

.stats .code {
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace;
}

.stats .ant-card-body {
  padding: 18px 24px 16px;
  color: rgba(0,0,0,0.45);
  min-height: 121px;
}

.stats.stats-minheight145 .ant-card-body {
  min-height: 145px;
}

.stats small {
  color: rgba(0,0,0,0.25);
}

.stats .ant-skeleton-content .ant-skeleton-title {
  width: 75%;
  margin-bottom: 0;
}

.stats .ant-carousel {
  margin-bottom: 16px;
}

.stats .ant-carousel .ant-skeleton-content .ant-skeleton-title {
  width: 50%;
}

.stats .ant-carousel .slick-dots {
  margin: 0;
  display: inline-block;
  right: 20px;
  left: auto;
  bottom: 20px;
}

.stats .ant-carousel .slick-dots li button {
  background: #5f26dd;
}

.stats .ant-carousel .slick-dots li.slick-active button {
  background: #5f26dd;
}

.ant-table-filter-trigger .anticon-search {
  padding: 4px;
  border-radius: 2px;
  color: #FFF;
  margin-top: -1px;
}

.stats .change.change-up {
  color: #389e0d;
}

.stats .change.change-down {
  color: #cf1322;
}

.stats .change .anticon {
  font-size: 85%;
  margin-right: 4px;
}

.refresh-button {
  position: absolute !important;
  margin-left: 10px;
  margin-top: -2px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  right: 20px;
}

a.dotted {
  border-bottom: 1px dashed rgba(95, 38, 221, 0.5);
}

.heights {
  margin-top: 8px;
}

.heights .heights-item {
  display: block;
  float: left;
  margin-right: 15px;
}

.ant-layout-footer .code {
  font-family: 'IBM Plex Mono', Monaco, Menlo, Consolas, 'Courier New', monospace;
}

.anchor-info {
  margin-top: 10px;
  margin-bottom: 5px;
}

.react-icons.react-icons-end {
  margin-left: 4px;
  margin-right: 0;
}

.featured {
  margin-top: 20px;
  background: #fffbe6;
  padding: 10px 20px;
  border: 1px solid #fff1b8;
  border-left: 5px solid #ffc53d;
}